<template>
  <RWCard title="Social Media Stream">
    <template #body>
      <div v-if="loading">
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="text@3"></v-skeleton-loader> <br />
        <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
        <v-skeleton-loader type="divider"></v-skeleton-loader> <br />
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="text@3"></v-skeleton-loader> <br />
        <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
      </div>
      <div v-else>
        <template v-if="success">
          <div
            class="card card-custom gutter-b shadow mt-2"
            v-for="(row, key) in streamList"
            :key="key"
          >
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Top-->
              <div class="d-flex align-items-center">
                <!--begin::Symbol-->
                <!-- <div class="symbol symbol-40 symbol-light-success mr-3">
                  <span class="symbol-label">
                    <img style="width: 23px" :src="row.image" />
                  </span>
                </div> -->
                <!--end::Symbol-->
                <!--begin::Info-->
                <div class="d-flex flex-column flex-grow-1">
                  <a
                    href="#"
                    class="
                      text-dark-75 text-hover-primary
                      mb-1
                      font-size-lg font-weight-bolder
                    "
                    >@rumahwebtweet</a
                  >
                  <span class="text-muted">{{ row.created_at }}</span>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Top-->
              <!--begin::Bottom-->
              <div class="pt-1">
                <!--begin::Text-->
                <p
                  class="text-dark-75 font-size-lg font-weight-normal pt-1 mb-2"
                >
                  {{ row.text }}
                  <a :href="row.readmore" target="_blank">selengkapnya</a>
                </p>
                <!--end::Text-->
                <!--begin::Action-->
                <div class="d-flex align-items-center">
                  <a class="mr-2 font-weight-bolder">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M16 15.632V12a3 3 0 00-3-3H6.162V5.526A2.526 2.526 0 018.688 3h11.79a2.526 2.526 0 012.526 2.526v7.58l.017 4.68a.5.5 0 01-.854.356l-2.51-2.51H16z" fill="#000"/><path d="M1.985 18v-5a2 2 0 012-2h8a2 2 0 012 2v5a2 2 0 01-2 2H4.101l-1.244 1.19a.5.5 0 01-.846-.36v-2.506A2.014 2.014 0 011.985 18zM6.5 14a.5.5 0 100 1h5a.5.5 0 100-1h-5zm3 2a.5.5 0 100 1h2a.5.5 0 100-1h-2z" fill="#000" opacity=".3"/></g></svg>
                    <span class="px-2">{{
                      row.public_metrics.reply_count
                    }}</span>
                  </a>
                  <a class="mr-2 font-weight-bolder">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M9 10v9l1.153.384a12 12 0 003.794.616h3.594a3 3 0 002.941-2.412l.75-3.746a3 3 0 00-3.142-3.581l-3.59.239.364-4.006A1.37 1.37 0 0013.5 5a2.65 2.65 0 00-2.272 1.286L9 10z" fill="#000"/><rect fill="#000" opacity=".3" x="2" y="9" width="5" height="11" rx="1"/></g></svg>
                    <span class="px-2">{{
                      row.public_metrics.like_count
                    }}</span>
                  </a>
                </div>
                <!--end::Action-->
              </div>
              <!--end::Bottom-->
            </div>
            <!--end::Body-->
          </div>
        </template>
        <div class="alert alert-warning" v-else>
          <i class="fa fa-exclamation-triangle"></i> {{ streamList }}
        </div>
      </div>
    </template>
  </RWCard>
</template>

<script>
import { ApiURL } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
export default {
  mixins: [ApiURL],
  components: {
    RWCard
  },
  data() {
    return {
      loading: true,
      streamList: [],
      success: false
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get sosial media stream list
     * -----------------------------------------
     */
    get_sosmed() {
      this.xhr_dashboard("sosmed", "?limit=2")
        .then(({ data }) => {
          this.success = true;
          this.streamList = data.data.sosmed.record;
        })
        .catch(err => {
          this.streamList = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.get_sosmed();
  }
};
</script>

<style scoped>
.symbol {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol.symbol-40 .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol .symbol-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  line-height: 0;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #c9f7f5;
  color: #1bc5bd;
}
.font-size-lg {
  font-size: 1.08rem;
}
.text-dark-75 {
  color: #3f4254 !important;
}
.font-weight-bolder {
  font-weight: 600 !important;
}
.min-h-265px {
  min-height: 265px !important;
}
</style>
