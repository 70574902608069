<template>
  <div>
    <b-row>
      <!-- left -->
      <b-col sm="6" class="my-4">
        <div v-if="loading.banner">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-card
            overlay
            :img-src="xhr.banner_image"
            img-alt="Card Image"
            text-variant="white"
          >
          </b-card>
        </div>
      </b-col>

      <!-- right -->
      <b-col sm="6">
        <b-row class="my-4">
          <b-col sm="6">
            <b-link
              target="_blank"
              href="https://www.rumahweb.com/domain-murah/"
              class="btn btn-primary btn-lg btn-block mb-3"
              >Beli Domain</b-link
            >
          </b-col>
          <b-col sm="6">
            <b-link
              target="_blank"
              href="https://www.rumahweb.com/hosting-murah/"
              class="btn btn-success btn-lg btn-block"
              >Beli Hosting</b-link
            >
          </b-col>
        </b-row>

        <!-- news -->
        <RWCard :bodyFit="true" title="News">
          <template #toolbar>
            <b-skeleton width="80px" v-if="loading.news"></b-skeleton>
            <b-link v-else :to="{ name: 'news-all' }">Lihat semua </b-link>
          </template>

          <template v-slot:body>
            <div v-if="loading.news">
              <v-skeleton-loader
                type="paragraph@2"
                style="padding: 1rem"
              ></v-skeleton-loader>
            </div>
            <div v-else>
              <ul class="list-group list-group-flush">
                <b-list-group-item
                  :href="news.link"
                  target="_blank"
                  class="flex-column align-items-start"
                  v-for="(news, index) in xhr.news.record"
                  :key="index"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="text-truncate">
                      <b-link
                        :to="{ name: 'news-single', params: { id: news.id } }"
                        >{{ news.title }}</b-link
                      >
                    </h6>
                  </div>

                  <p class="mb-1 text-truncate">
                    {{ news.announcement }}
                  </p>

                  <div class="d-flex w-100 justify-content-end">
                    <small class="text-muted">{{ news.tanggal }}</small>
                  </div>
                </b-list-group-item>
              </ul>
            </div>
          </template>
        </RWCard>
        <!-- end news -->
      </b-col>
    </b-row>

    <!-- search domain -->
    <b-card class="mt-4 shadow-sm" id="cari-domain">
      <h2>Mau beli domain baru? Cek di sini!</h2>
      <b-form inline action="https://order2.rumahweb.com/" target="_blank">
        <label class="sr-only" for="inline-form-input-name">Name</label>
        <input
          type="text"
          name="domain"
          id="inline-form-input-name"
          class="mb-2 mr-sm-2 mb-sm-0 form-control"
          placeholder="rumahweb.com"
          autocomplete="off"
        />

        <b-button variant="success" type="submit">Cari</b-button>
      </b-form>
    </b-card>

    <!-- counter -->
    <b-row class="mt-5">
      <b-col sm="3" cols="6" class="mb-3">
        <!-- aktif -->
        <b-card
          class="shadow count-layanan"
          @click="redirect('product/hosting')"
        >
          <h2>
            <div v-if="loading.counter">
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </div>
            <div v-else>
              {{ xhr.counter.layanan }} <i class="fa fa-cubes"></i>
            </div>
          </h2>
          <p class="text-muted">Layanan Akif</p>
        </b-card>
        <!-- end aktif -->
      </b-col>
      <b-col sm="3" cols="6">
        <b-card class="shadow count-domain" @click="redirect('product/domain')">
          <h2>
            <div v-if="loading.counter">
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </div>
            <div v-else>
              {{ xhr.counter.domain }} <i class="fa fa-globe"></i>
            </div>
          </h2>
          <p class="text-muted">Domain</p>
        </b-card>
      </b-col>
      <b-col sm="3" cols="6">
        <b-card
          class="shadow count-invoice"
          @click="redirect('invoice?status=unpaid')"
        >
          <h2>
            <div v-if="loading.counter">
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </div>
            <div v-else>
              {{ xhr.counter.invoice }} <i class="fa fa-receipt"></i>
            </div>
          </h2>
          <p class="text-muted">Invoice</p>
        </b-card>
      </b-col>
      <b-col sm="3" cols="6">
        <b-card
          class="shadow count-support"
          @click="redirect('https://www.rumahweb.com/kontak/', false)"
        >
          <h2>
            <div v-if="loading.counter">
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </div>
            <div v-else>
              <i class="fa fa-headset"></i>
            </div>
          </h2>
          <p class="text-muted">Support Ticket</p>
        </b-card>
      </b-col>
    </b-row>
    <!-- end counter -->

    <!-- invoice -->
    <b-row class="mt-5">
      <b-col sm="6">
        <RWCard title="Invoice">
          <template v-slot:toolbar>
            <div class="text-right">
              <div v-if="loading.invoice">
                <v-skeleton-loader type="chip"></v-skeleton-loader>
              </div>
              <div v-else>
                <span>{{ xhr.invoice.sum.unpaid }} unpaid</span> <br />
                <span class="text-danger"
                  >{{ xhr.invoice.sum.pastdue }} past due</span
                >
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div v-if="loading.invoice">
              <v-skeleton-loader type="paragraph@3"></v-skeleton-loader>
            </div>
            <div v-else>
              <table class="table table-hover table-striped table-sm">
                <thead>
                  <tr>
                    <th>Jatuh Tempo</th>
                    <th>No Invoice</th>
                    <th>Jumlah</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(inv, index) in xhr.invoice.latest" :key="index">
                    <td>
                      <b>{{ inv.duedate }}</b>
                    </td>
                    <td>{{ inv.id }}</td>
                    <td>{{ inv.total }}</td>
                    <td>
                      <b-link
                        :to="{ path: 'invoice/' + inv.id }"
                        class="btn btn-success btn-sm"
                        >Bayar</b-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-link
                class="btn btn-block btn-primary"
                :to="{ path: 'invoice' }"
                >Lihat Semua Invoice</b-link
              >
            </div>
          </template>
        </RWCard>
      </b-col>
      <b-col sm="6">
        <!-- guide -->
        <RWWidgetGuides
          :success="success.guide"
          :loading="loading.guide"
          :data="xhr.guide"
        ></RWWidgetGuides>
      </b-col>
    </b-row>
    <!-- end invoice -->

    <!-- sosmed & afiliasi -->
    <b-row class="mt-3">
      <b-col sm="6">
        <SosmedStream></SosmedStream>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ApiURL } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
import SosmedStream from "@/views/pages/dashboard/slot/slotSosmedStream.vue";
import RWWidgetGuides from "@/views/partials/widgets/rw/widgetGuides";
// import ShowingInvoice from "@/views/pages/dashboard/slot/slotInvoice.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  mixins: [ApiURL],
  components: {
    RWCard,
    SosmedStream,
    RWWidgetGuides
    // ShowingInvoice
  },
  data() {
    return {
      xhr: {
        banner_image: null,
        news: null,
        counter: null,
        invoice: null,
        guide: Object
      },
      loading: {
        banner: true,
        news: true,
        counter: true,
        invoice: true,
        guide: true,
        afiliasi: true
      },
      success: {
        guide: false
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get banner dashboard
     * -----------------------------------------
     */
    get_banner() {
      this.xhr_dashboard("banner").then(({ data }) => {
        this.xhr.banner_image = data.data.image;
        this.loading.banner = false;
      });
    },

    /**
     * -----------------------------------------
     * get news dashboard
     * -----------------------------------------
     */
    get_news() {
      this.xhr_news("index", "?limit=2").then(({ data }) => {
        this.xhr.news = data.data.news;
        this.loading.news = false;
      });
    },

    /**
     * -----------------------------------------
     * get counter / sum layanan dashboard
     * -----------------------------------------
     */
    get_counter() {
      this.xhr_dashboard("sum").then(({ data }) => {
        this.xhr.counter = data.data;
        this.loading.counter = false;
      });
    },

    /**
     * -----------------------------------------
     * get guides
     * -----------------------------------------
     */
    get_guide() {
      this.xhr_dashboard("guide")
        .then(({ data }) => {
          this.xhr.guide = data.data.guide;
          this.success.guide = true;
        })
        .catch(({ response }) => {
          this.xhr.guide = response.data;
        })
        .finally(() => {
          this.loading.guide = false;
        });
    },

    /**
     * -----------------------------------------
     * get invoice data & sum dashboard
     * -----------------------------------------
     */
    get_invoice() {
      this.xhr_dashboard("suminvoice").then(({ data }) => {
        this.xhr.invoice = {
          sum: data.data.sum,
          latest: data.data.latest
        };
        this.loading.invoice = false;
      });
    },

    /**
     * -----------------------------------------
     * redirect box
     * -----------------------------------------
     */
    redirect(path, internal = true) {
      if (internal) {
        this.$router.push({ path: path });
      } else {
        window.open(path, "_blank");
      }
    }
  },
  mounted() {
    this.get_banner();
    this.get_news();
    this.get_counter();
    this.get_invoice();
    this.get_guide();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard", route: "/" }]);
  }
};
</script>

<style scoped>
button {
  color: white !important;
}

#cari-domain {
  background-color: #47ccff;
}

#cari-domain h2 {
  color: #333 !important;
}

@media (max-width: 768px) {
  #cari-domain h2 {
    font-size: 1.5em;
  }
}

.count-layanan {
  background-color: #e4f0fd;
}
.count-domain {
  background-color: #d2f6f4;
}
.count-invoice {
  background-color: #fdf4e0;
}
.count-support {
  background-color: #fae3e5;
}

[class*="count"] {
  cursor: pointer;
}

table thead tr th,
table tbody tr td {
  vertical-align: middle;
  text-align: center;
}
</style>
